/* eslint-disable jsx-a11y/no-static-element-interactions,no-undef,max-len */

/** handles the display of a Google Map */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadGoogle } from '@justpark/helpers/src/maps/maps';

import '../../../../static/scss/components/panorama.scss';

type Props = {
  values?: any;
  panoramaOptions?: any;
};

class GooglePanoMap extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.state = {
      mounted: false
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });

    loadGoogle().then(() => {
      if (this.state.mounted) {
        this.update();
      }
    });
  }

  componentDidUpdate() {
    this.update();
  }

  componentWillUnmount() {
    this.state = {
      mounted: false
    };
  }

  update() {
    if (typeof google !== 'undefined' && google.maps) {
      const streetViewCheck = new google.maps.StreetViewService();
      let latLng;

      const listing = this.props.values;
      const metresToSearch = 50;

      if (
        listing.streetviewData &&
        listing.streetviewData.lat &&
        listing.streetviewData.lng
      ) {
        // then streetviewData exists
        latLng = new google.maps.LatLng(
          listing.streetviewData.lat,
          listing.streetviewData.lng
        );

        const pano = new google.maps.StreetViewPanorama(
          this.scHolder,
          this.props.panoramaOptions
        );
        pano.setPosition(latLng);

        const panoData = {
          zoom: listing.streetviewData.zoom,
          pitch: listing.streetviewData.pitch,
          heading: listing.streetviewData.heading
        };
        pano.setPov(panoData);
        // this.bindEventListeners(pano, Object.assign({}, latLng, panoData));
      } else if (!listing.lat && !listing.lng) {
        // if there is no listing latitude and longitude from the geocode - when the listing is edited,
        // we should hide the pano because we have nothing to getPanorama with
        this.svHolder.style.display = 'none'; // hide the pano node
      } else {
        // get a pano object based on the current latitude longitude
        latLng = new google.maps.LatLng(listing.lat, listing.lng);

        streetViewCheck.getPanorama(
          { location: latLng, radius: metresToSearch },
          (data, status) => {
            if (!status === google.maps.StreetViewStatus.OK) {
              this.svHolder.style.display = 'none'; // hide the pano node
            } else {
              const pano = new google.maps.StreetViewPanorama(
                this.scHolder,
                this.props.panoramaOptions
              );
              latLng = {
                lat: data.location.latLng.lat(),
                lng: data.location.latLng.lng()
              };
              pano.setPosition(latLng);
              // this.bindEventListeners(pano, data.location.pano);
            }
          }
        );
      }
    }
  }

  toggleState() {
    this.scOverlay.style.display = 'none';
  }

  render() {
    return (
      <div
        className="streetview-holder"
        ref={(div) => {
          this.svHolder = div;
        }}
      >
        <div
          className="streetview-overlay"
          ref={(div) => {
            this.scOverlay = div;
          }}
          onClick={this.toggleState.bind(this)}
        />

        <div
          className="streetview-canvas"
          ref={(div) => {
            this.scHolder = div;
          }}
          width="300px"
          height="100px"
        />
      </div>
    );
  }
}

GooglePanoMap.defaultProps = {
  values: {},
  panoramaOptions: {
    scrollwheel: false,
    addressControl: false,
    linksControl: true,
    motionTracking: false,
    pov: {
      heading: 0,
      pitch: 0,
      zoom: 0
    }
  }
};

export default connect()(GooglePanoMap);
