import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@justpark/ui/src/components/Typography';
import StreetviewIcon from '@justpark/ui/src/components/Icons/StreetviewIcon';
import '../../../static/scss/components/streetviewButton.scss';

type StreetView = {
  lat: number;
  lng: number;
  heading: number;
  pitch: number;
  zoom: number;
};
type Props = {
  streetView: StreetView;
  onClick?: () => void;
  cypress?: string;
};

const StreetViewButton = ({
  streetView: { lat, lng, pitch, zoom, heading },
  onClick = () => {},
  cypress = null
}: Props) => {
  const { t } = useTranslation();

  const href = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat},${lng}&pitch=${pitch}&zoom=${zoom}&heading=${heading}`;

  return (
    <a
      data-cy={`${cypress ? `${cypress}--streetview-button` : null}`}
      className="c-streetview-button"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
    >
      <StreetviewIcon />
      <Typography bold className="c-streetview-button__text">
        {t('cta:viewStreetView', 'View streetview')}
      </Typography>
    </a>
  );
};

export default StreetViewButton;
