import React from 'react';
import { loadGoogle } from '@justpark/helpers/src/maps/maps';
import GoogleMapContext from './GoogleMapContext';
import type { Google } from '../types';
import BrowserContext from '../../../context/BrowserContext';

import '../../../../static/scss/components/map/googleMapLoader.scss';

declare let google: Google;
type Props = {
  visible?: boolean;
  center?: {
    lat: number;
    lng: number;
  };
  zoomControl?: boolean;
  mapTypeControl?: 'none' | 'standard';
  zoom?: number;
  maxZoom?: number;
  mapTypeId?: 'roadmap' | 'satellite';
  children?: any;
  scrollwheel?: true;
  disableDefaultUI?: boolean;
};
type State = {
  init: boolean;
};

const getMapTypeControls = (type, disableDefaultUI) => {
  if (type === 'standard' && !disableDefaultUI) {
    return {
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        mapTypeIds: [
          google.maps.MapTypeId.ROADMAP,
          google.maps.MapTypeId.SATELLITE
        ]
      }
    };
  }

  return {
    mapTypeControl: false
  };
};

/**
 * Class based component that loads the google maps SDK and creates a new Google Map
 * When ready it renders it's children inside a context provider so we know that all children will have access to google JS
 */
class GoogleMapLoader extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      init: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.initWhenVisible();
  }

  componentDidUpdate(prevProps: GoogleMapLoaderProps) {
    const { zoomControl } = this.props;

    if (this.gMap !== undefined && zoomControl !== prevProps.zoomControl) {
      this.gMap.setOptions({ zoomControl });
    }

    this.initWhenVisible();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  initWhenVisible() {
    if (this.state.init || this.visibleInit || this.props.visible !== true) {
      return;
    }

    this.visibleInit = true;

    loadGoogle().then(() => {
      if (this.mounted) {
        this.initMap();
      }
    });
  }

  initMap() {
    const mapElement = this.map;
    const {
      center,
      zoom,
      maxZoom,
      mapTypeId,
      scrollwheel,
      zoomControl,
      disableDefaultUI,
      mapTypeControl
    } = this.props;

    const rasterMap = this.context.lessThan?.medium ?? true;

    // https://developers.google.com/maps/documentation/javascript/webgl/support?_gl=1*gxgzlx*_up*MQ..*_ga*MTI3NDUwODgzNi4xNzEzNDMwOTI0*_ga_NRWSTWS78N*MTcxMzQzMDkyMy4xLjAuMTcxMzQzMDkyMy4wLjAuMA..#mobile_web_support
    // Only use vector map on desktop
    const mapId = rasterMap ? '305f980f1b94968b' : '6d523bdad739f76c';

    this.gMap = new google.maps.Map(mapElement, {
      center,
      zoom,
      ...getMapTypeControls(mapTypeControl, disableDefaultUI),
      disableDefaultUI,
      clickableIcons: false,
      streetViewControl: false,
      gestureHandling: 'greedy',
      fullscreenControl: false,
      zoomControl,
      maxZoom,
      scrollwheel,
      minZoom: 6,
      mapTypeId,
      tilt: 0,
      rotateControl: false,
      isFractionalZoomEnabled: true,
      mapId
    });

    if (window.Cypress) {
      google.maps.event.addListenerOnce(this.gMap, 'tilesloaded', () => {
        this.gMap.hasLoaded = true;
      });

      window.CypressGoogleMap = this.gMap;
    }

    this.setState({
      init: true
    });
  }

  render() {
    const { init } = this.state;
    const { children } = this.props;

    return (
      <div
        data-cy="search-results-map"
        className={`map ${this.props.visible ? '' : 'mapHidden'}`}
        ref={(div) => {
          this.map = div;
        }}
      >
        {init && (
          <GoogleMapContext.Provider value={this.gMap}>
            {children}
          </GoogleMapContext.Provider>
        )}
      </div>
    );
  }
}

export default GoogleMapLoader;

GoogleMapLoader.contextType = BrowserContext;

GoogleMapLoader.defaultProps = {
  zoom: 14,
  maxZoom: 19,
  mapTypeId: 'roadmap',
  mapTypeControl: 'standard',
  scrollwheel: true,
  disableDefaultUI: false,
  visible: true
};
