// @ts-nocheck
import React from "react";
import bem from "@justpark/helpers/src/bem/bem";
import InfoBoxIcon from "../../../svg/infobox-info-Icon.svg";
import "./infoBoxInfoIncon.scss";

type Props = {
  type?: "primary" | "secondary" | "yellow";
};
const InfoBoxInfoIcon = ({ type }: Props) => (
  <InfoBoxIcon
    className={bem("c-infobox-info-icon", null, {
      type
    })}
  />
);
export default InfoBoxInfoIcon;
