import React, { useMemo } from 'react';
import ImageGalleryGrid from '@justpark/ui/src/components/ImageGalleryGrid';
import type { Listing } from '../../generated/graphql';

import styles from '../../../static/scss/components/listingDetails/information.module.scss';

type Props = {
  location: Listing;
  t: (b: string, a: string) => string;
  isLCP: boolean;
};

const ListingGallery = ({ location, t, isLCP }: Props) => {
  const images = useMemo(
    () =>
      location.photos.map(({ id, normal }) => ({
        id,
        src: normal.url,
        width: normal.width,
        height: normal.height
      })),
    [location.photos]
  );

  if (images?.length === 0) {
    return null;
  }

  return (
    <div className={styles.images}>
      <ImageGalleryGrid images={images} t={t} isLCP={isLCP} />
    </div>
  );
};

export default ListingGallery;
