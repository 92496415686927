import queryString from 'query-string';
import {
  toCamelCase,
  toSnakeCase
} from '@justpark/helpers/src/caseConverter/caseConverter';
import type { Dayjs } from 'dayjs';

const buildSlugParams = (
  dates: {
    arriving: Dayjs;
    leaving?: Dayjs;
  },
  isMonthly: boolean,
  monthlyDuration?: string | null,
  monthlyType?: string | null,
  airport: boolean | undefined | null = false
): string => (caseConvert: 'camel' | 'snake' = 'camel') => {
  const { arriving, leaving } = dates;
  const transform = caseConvert === 'camel' ? toCamelCase : toSnakeCase;

  const startTimeParams = {
    startDate: arriving.format('YYYY-MM-DD'),
    startTime: arriving.format('HH:mm:ss')
  };

  const leavingTimeParams = !isMonthly
    ? {
        endDate: leaving.format('YYYY-MM-DD'),
        endTime: leaving.format('HH:mm:ss')
      }
    : {
        monthlyDuration,
        monthlyType
      };

  const queryParams = {
    ...startTimeParams,
    ...leavingTimeParams,
    ...(airport && {
      airport
    })
  };

  return queryString.stringify(transform(queryParams));
};

export default buildSlugParams;
