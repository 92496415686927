// @ts-nocheck
import React from "react";
import Typography from "../Typography";
import LinkButton from "../LinkButton";
import Shield from "../FontAwesome/Shield";
import Star from "../FontAwesome/Star";
import CheckCircle from "../FontAwesome/CheckCircle";
import defaultTranslate from "../../helpers/defaultTranslate";
import type { Translate } from "../../types/i18n";
import UserHeadset from "../FontAwesome/UserHeadset";
import styles from "./trustMarkers.module.scss";

const cypress = "trust-markers";
type Props = {
  showCancellationModal: boolean;
  onClickCancellationModal: () => void;
  isBold: boolean;
  className: string;
  t?: Translate;
  trustPilotText: string;
};
const TrustMarkers = ({
  showCancellationModal,
  onClickCancellationModal,
  isBold,
  className,
  trustPilotText,
  t = defaultTranslate
}: Props) => (
  <ul className={`${className} ${styles.list}`}>
    <li className={styles.item} data-cy={`${cypress}-trust-pilot`}>
      <Star className={styles.icon} size={24} />
      <Typography bold={isBold}>{trustPilotText}</Typography>
    </li>
    <li className={styles.item} data-cy={`${cypress}-trusted-count`}>
      <CheckCircle className={styles.icon} size={24} />
      <Typography bold={isBold}>
        {t("ui:bookWithConfidence-trustedCount", "Trusted by 10m drivers")}
      </Typography>
    </li>
    <li className={styles.item} data-cy={`${cypress}-cancellation`}>
      <Shield className={styles.icon} size={24} />
      {showCancellationModal && onClickCancellationModal ? (
        <LinkButton
          onClick={onClickCancellationModal}
          className={styles.cancellationButton}
        >
          <Typography bold={isBold}>
            {t(
              "ui:bookWithConfidence-cancellation",
              "Free cancellation up to 24 hours before arrival"
            )}
          </Typography>
        </LinkButton>
      ) : (
        <Typography bold={isBold}>
          {t(
            "ui:bookWithConfidence-cancellation",
            "Free cancellation up to 24 hours before arrival"
          )}
        </Typography>
      )}
    </li>
    <li className={styles.item} data-cy={`${cypress}-customer-service`}>
      <UserHeadset className={styles.icon} size={24} />
      <Typography bold={isBold}>
        {t(
          "ui:bookWithConfidence-customerService",
          "Award-winning customer service"
        )}
      </Typography>
    </li>
  </ul>
);
export default TrustMarkers;
