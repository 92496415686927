// @ts-nocheck
import React, { useState, useMemo, useRef } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Typography from "../Typography";
import styles from "./facilityList.module.scss";
import tag from "./tag.module.scss";

type Facility = {
  title: string;
  description: string;
  hidden: boolean;
};
type Props = {
  facilities: Array<Facility>;
};
type FacilityProps = {
  facility: Facility;
};
const Tag = ({ facility }: FacilityProps) => {
  return (
    <div data-cy="facility-tag" className={tag.base}>
      <Typography size="secondary">{facility.title}</Typography>
    </div>
  );
};

type FacilityListItem = {
  facility: Facility;
  index: number;
  restCount: number;
};

const FacilityListItem = ({ facility, index, restCount }: FacilityListItem) => {
  const nodeRef = useRef(null);

  return (
    <CSSTransition
      key={facility.title}
      timeout={{
        enter: 250 * index
      }}
      classNames={{
        appear: "my-appear",
        appearActive: "my-active-appear",
        appearDone: "my-done-appear",
        enter: styles.itemEnter,
        enterActive: styles.itemEnterActive,
        enterDone: styles.itemDone,
        exit: "my-exit",
        exitActive: "my-active-exit",
        exitDone: "my-done-exit"
      }}
      appear
      in
      nodeRef={nodeRef}
    >
      <div
        style={{
          transitionDelay: `${(250 / restCount) * index}ms`
        }}
        ref={nodeRef}
      >
        <Tag facility={facility} />
      </div>
    </CSSTransition>
  );
};

const FacilityList = ({ facilities }: Props) => {
  const filteredFacilities = useMemo(
    () => facilities.filter((item) => item.hidden === false),
    [facilities]
  );
  const { length: count } = filteredFacilities;
  const [expanded, setExpanded] = useState(false);
  const showMoreCount = count - 2;
  const shouldShowMore = !expanded && showMoreCount > 0;
  const firstTwo = filteredFacilities.slice(0, 2);
  const rest = filteredFacilities.slice(2);
  const buttonClick = () => setExpanded(true);
  const restCount = rest.length;

  return (
    <div data-cy="facility-list" className={styles.base}>
      {firstTwo.map((facility) => (
        <div key={facility.title}>
          <Tag facility={facility} />
        </div>
      ))}

      <TransitionGroup component={null}>
        {expanded &&
          rest.map((facility, index) => (
            <FacilityListItem
              facility={facility}
              index={index}
              restCount={restCount}
              key={facility.title}
            />
          ))}
      </TransitionGroup>
      {shouldShowMore && (
        <button
          data-cy="facilities-show-more"
          className={styles.button}
          type="button"
          onClick={buttonClick}
        >
          <Typography color="primary-inverse" size="secondary">
            +{showMoreCount}
          </Typography>
        </button>
      )}
    </div>
  );
};
export default FacilityList;
