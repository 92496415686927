import React from 'react';
import Modal from '@justpark/ui/src/components/Modal';
import CrossIcon from '@justpark/ui/src/components/Icons/CrossIcon';
import GooglePanoMap from '../Search/Map/GooglePanoMap';

import styles from '../../../static/scss/components/streetViewModal.module.scss';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  location: any;
};

const StreetViewModal = ({ onClose, isOpen, location }: Props) => (
  <Modal
    onClose={onClose}
    id="street-view-modal"
    portalParent="#portal"
    isOpen={isOpen}
    closeOnOutsideClick
  >
    <div className={styles.modalWrapper}>
      <CrossIcon onClick={onClose} className={styles.closeIcon} />
      <GooglePanoMap values={location} />
    </div>
  </Modal>
);

export default StreetViewModal;
