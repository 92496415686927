import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useDarkly from './useDarkly';

const useTrustPilotReview = (isFlat: boolean) => {
  const { t } = useTranslation();
  const trustPilotScore = useDarkly('trustpilot-data');

  const data = trustPilotScore || {};
  const { rating = 4.5, reviewCount = 107924 } = data;

  const count = useMemo(() => {
    const lowestThousand = 1000 * Math.floor(reviewCount / 1000);

    try {
      return new Intl.NumberFormat('en-GB').format(lowestThousand);
    } catch {
      return lowestThousand;
    }
  }, [reviewCount]);

  const subtextNormal = 'ui:trustpilot-tagline';
  const subtextShort = 'ui:trustpilot-taglineShort';

  const subtext = t(isFlat ? subtextShort : subtextNormal, {
    count
  });

  return useMemo(() => ({ subtext, rating }), [subtext, rating]);
};

export default useTrustPilotReview;
