/* eslint-disable react/no-unstable-nested-components */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ReadMore from '@justpark/ui/src/components/ReadMore';
import useToggle from '@justpark/ui/src/hooks/useToggle';
import FacilityList from '@justpark/ui/src/components/FacilityList';
import BrowserContext from '../context/BrowserContext';
import StreetViewModal from './UI/StreetViewModal';
import StreetViewButton from './UI/StreetViewButton';

import styles from '../../static/scss/components/listingInformation.module.scss';

export type Facility = {
  description: string;
  hidden: boolean;
  id: string;
  isRestricted: string;
  title: string;
};

export type FacilitiesType = Array<Facility>;

export type Location = {
  type: 'justpark' | 'driveup' | 'driveup-onstreet' | 'justpark_and_ride';
  description: string;
  formattedAddress: string;
  title: string;
  id: number;
  slug: string;
  photos: Array<any>;
  checkoutUrl: string;
  streetviewData: any;
  facilities: FacilitiesType;
  reviews: Array<any> | undefined | null;
  lat: number;
  lng: number;
  feedback: {
    averageRating: string;
    count: number;
    latestReview: {
      rating: string;
    };
  };
  owner: {
    id: string;
    firstName: string;
    slug: string;
  };
  active: boolean;
  acceptsDriveup: boolean;
  acceptsPrebook: boolean;
};
type Props = {
  topSlot?: React.Node;
  middleSlot?: React.Node;
  bottomSlot?: React.Node;
  location: Location | null;
};

const ListingInformation = ({
  topSlot,
  middleSlot,
  location,
  bottomSlot
}: Props) => {
  const [streetViewEnabled, setStreetViewEnabled] = useToggle(false);
  const { t } = useTranslation();
  const browser = useContext(BrowserContext);
  const mobile = browser.lessThan.medium;
  const { description, facilities } = location;

  return (
    <div className={styles.base}>
      {topSlot}

      {facilities?.length ? <FacilityList facilities={facilities} /> : null}

      {middleSlot}

      <ReadMore
        buttonClassName={styles.readMoreButton}
        label={t('cta:readMore', 'Read more')}
      >
        {description && (
          <div className={styles.description}>
            {description.split('\n').map((item, key) => (
              <span key={key}>
                {item}
                <br />
              </span>
            ))}
          </div>
        )}
      </ReadMore>

      {location?.streetviewData && (
        <>
          <div className={styles.streetViewButton}>
            <StreetViewButton
              onClick={(e) => {
                if (mobile) {
                  e.preventDefault();
                  setStreetViewEnabled();
                }
              }}
              streetView={location.streetviewData}
            />
          </div>
          {mobile && (
            <StreetViewModal
              isOpen={streetViewEnabled}
              onClose={setStreetViewEnabled}
              location={location}
            />
          )}
        </>
      )}

      {bottomSlot}
    </div>
  );
};

export default ListingInformation;
