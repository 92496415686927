import { useEffect } from 'react';
import { gtagTrack } from '../helpers/Tracking/Analytics';
import { ampli } from '../helpers/Tracking/amplitude';

export const trackViewItem = (id, title) => {
  gtagTrack('view_item', {
    items: [
      {
        item_id: id,
        item_name: title
      }
    ]
  });
};

const useTrackViewItem = (id, title) => {
  useEffect(() => {
    trackViewItem(id, title);

    ampli.listingDetailsPageViewed({
      listing_id: id
    });
  }, [id, title]);
};

export default useTrackViewItem;
