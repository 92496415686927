/* globals google */
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import GoogleMapContext from './GoogleMapContext';

type Props = {
  children: any;
  lat: any;
  lng: any;
  zIndex?: number;
  yOffset?: number;
  visible?: boolean;
};

const OverlayMarker = ({
  lat,
  lng,
  children,
  zIndex = 1,
  yOffset = 0,
  visible = true
}: Props) => {
  const [overlayAdded, setOverlayAdded] = useState(false);
  const overlayRef = useRef(null);
  const overlayDiv = useRef(null);
  const initialVisibility = useRef(visible);
  const map = useContext(GoogleMapContext);

  useEffect(() => {
    if (overlayDiv.current) {
      overlayDiv.current.style.zIndex = google.maps.Marker.MAX_ZINDEX + zIndex;
    }
  }, [zIndex, overlayAdded]);

  useEffect(() => {
    if (overlayDiv.current) {
      overlayDiv.current.style.opacity = visible ? 1 : 0;
      overlayDiv.current.style.pointerEvents = visible ? 'all' : 'none';
    }
  }, [visible]);

  const onAdd = useCallback(() => {
    const overlayView = overlayRef.current;
    const div = document.createElement('div');

    div.style.borderStyle = 'none';
    div.style.borderWidth = '0px';
    div.style.zIndex = google.maps.Marker.MAX_ZINDEX + 1;
    div.style.transform = `translate(-50%, calc(-100% + ${yOffset}px))`;
    div.style.position = 'absolute';
    div.style.top = 0;
    div.style.left = 0;
    div.style.opacity = initialVisibility.current ? 1 : 0;
    div.style.transition = 'opacity 0.25s ease-in-out';
    div.style.pointerEvents = initialVisibility.current ? 'all' : 'none';

    const panes = overlayView.getPanes();

    if (panes) {
      panes.overlayMouseTarget.appendChild(div);
      overlayDiv.current = div;
      setOverlayAdded(true);
    }
  }, [setOverlayAdded, overlayDiv, overlayRef, yOffset]);

  const draw = useCallback(() => {
    const overlayView = overlayRef.current;

    const overlayProjection = overlayView.getProjection();

    if (overlayProjection) {
      const latLng = new google.maps.LatLng(lat, lng);

      const position = overlayProjection.fromLatLngToDivPixel(latLng);

      const div = overlayDiv.current;

      if (div) {
        div.style.transform = `translate(calc(-50% + ${position.x}px), calc(-100% + ${position.y}px + ${yOffset}px))`;
      }
    }
  }, [lat, lng, overlayRef, overlayDiv, yOffset]);

  const onRemove = useCallback(() => {
    const div = overlayDiv.current;

    if (div) {
      div.parentNode.removeChild(div);
      setOverlayAdded(false);
    }
  }, [overlayDiv, setOverlayAdded]);

  useEffect(() => {
    const overlayView = new google.maps.OverlayView();
    overlayRef.current = overlayView;

    overlayView.onAdd = onAdd;
    overlayView.draw = draw;
    overlayView.onRemove = onRemove;

    overlayView.setMap(map);

    return () => {
      if (overlayView) {
        overlayView.setMap(null);
      }
    };
  }, [map, onAdd, draw, onRemove]);

  return overlayAdded ? createPortal(children, overlayDiv.current) : null;
};

export default OverlayMarker;
