// @ts-nocheck
import React from "react";
import Typography from "../Typography";
import TrustMarkers from "../TrustMarkers";
import type { Translate } from "../../types/i18n";
import defaultTranslate from "../../helpers/defaultTranslate";
import styles from "./bookWithConfidence.module.scss";

type Props = {
  isBold: boolean;
  showCancellationModal: boolean;
  onClickCancellationModal: () => void;
  t?: Translate;
  trustPilotText: string;
};
const BookWithConfidence = ({
  isBold,
  showCancellationModal,
  onClickCancellationModal,
  trustPilotText,
  t = defaultTranslate
}: Props) => (
  <div data-cy="book-with-confidence" className={styles.base}>
    <div className={styles.header}>
      <Typography bold color="white">
        <h3>{t("ui:bookWithConfidence-heading", "Book with confidence")}</h3>
      </Typography>
    </div>

    <TrustMarkers
      t={t}
      isBold={isBold}
      showCancellationModal={showCancellationModal}
      onClickCancellationModal={onClickCancellationModal}
      className={styles.list}
      trustPilotText={trustPilotText}
    />
  </div>
);
export default BookWithConfidence;
