import React from 'react';
import BookWithConfidence from '@justpark/ui/src/components/BookWithConfidence';
import { useTranslation } from 'react-i18next';
import useTrustPilotReview from '../hooks/useTrustPilotReview';

type Props = {
  isBold?: boolean;
  showCancellationModal?: boolean;
  onClickCancellationModal?: () => void;
};
const ConnectedBookWithConfidence = ({
  isBold = false,
  showCancellationModal = false,
  onClickCancellationModal = () => {}
}: Props) => {
  const { t } = useTranslation();
  const { subtext } = useTrustPilotReview(true);

  return (
    <BookWithConfidence
      trustPilotText={subtext}
      isBold={isBold}
      t={t}
      showCancellationModal={showCancellationModal}
      onClickCancellationModal={onClickCancellationModal}
    />
  );
};

export default ConnectedBookWithConfidence;
