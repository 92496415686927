import React, { useEffect } from 'react';
import GoogleMapContext from '../components/GoogleMapContext';
import type { Google } from '../types';

declare let google: Google;

const useAddEventListenerToMap = (eventName, callback) => {
  const map = React.useContext(GoogleMapContext);

  useEffect(() => {
    const listener = google.maps.event.addListener(map, eventName, callback);

    return () => {
      google.maps.event.removeListener(listener);
    };
  }, [map, eventName, callback]);
};

export default useAddEventListenerToMap;
