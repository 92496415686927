// @ts-nocheck
import React from "react";
import dayjs from "dayjs";
import Typography from "../Typography";
import StarRating from "../StarRating";
import ReadMore from "../ReadMore";
import styles from "./review.module.scss";

type T = (b: string, a: string) => string;
export type ReviewItem = {
  sender: string;
  createdAt: Date | string | number;
  rating: number;
  comment: string;
  source: string;
};
type Props = {
  review: ReviewItem;
  t: T;
  containerClassName?: string;
  cypress?: string;
};
const Review = ({ review, t, containerClassName, cypress = "" }: Props) => {
  return (
    <div
      data-cy={cypress}
      className={`${styles.reviewContainer} ${containerClassName}`}
    >
      <div className={styles.reviewHeading}>
        <Typography size="secondary" bold>
          {review.sender}
        </Typography>
        <Typography size="subtext" className={styles.date}>
          <span>
            {dayjs(review.createdAt).format("Do MMMM YYYY")}{" "}
            {review.source === "trustpilot" &&
              t("search:onTrustPilot", "on TrustPilot")}
          </span>
        </Typography>
      </div>

      <StarRating rating={review.rating} hideCount onClickStar={() => {}} />

      <ReadMore
        buttonClassName={styles.readMoreBtn}
        label={t("cta:readMore", "Read more")}
      >
        <Typography size="secondary" className={styles.comment}>
          <div>{review.comment}</div>
        </Typography>
      </ReadMore>
    </div>
  );
};
export default Review;
