// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import useToggle from "../../hooks/useToggle";
import ChevronIcon from "../Icons/ChevronIcon";
import LinkButton from "../LinkButton";
import styles from "./readMore.module.scss";

type Props = {
  children: React.ReactNode;
  label?: string;
  buttonClassName?: string;
};

const ReadMore = ({
  children,
  label = "Read more",
  buttonClassName = ""
}: Props) => {
  const [hasOverflow, setHasOverflow] = useState(false);
  const [isOpen, toggleValue] = useToggle(false);
  const showReadMore = !isOpen && hasOverflow;
  const readMoreRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (isOpen) return undefined;
    if (!("ResizeObserver" in window)) {
      // If ResizeObserver is not supported fallback to showing the whole content
      toggleValue();
      return undefined;
    }
    const isTextClamped = (elm) => elm.scrollHeight > elm.clientHeight;
    const checkButtonAvailability = () => {
      if (readMoreRef.current) {
        setHasOverflow(isTextClamped(readMoreRef.current));
      }
    };
    if (readMoreRef.current) {
      const observer = new ResizeObserver(() => {
        checkButtonAvailability();
      });
      observer.observe(readMoreRef.current);

      // Callback fired when component is unmounted
      return () => {
        observer.disconnect();
      };
    }
    return undefined;
  }, [readMoreRef, isOpen, toggleValue]);
  return (
    <>
      <div
        ref={readMoreRef}
        className={`${styles.content} ${isOpen ? styles.expanded : ""} `}
      >
        {children}
      </div>

      {showReadMore && (
        <LinkButton
          className={`${styles.button} ${buttonClassName}`}
          onClick={toggleValue}
        >
          {label}
          <ChevronIcon direction="down" />
        </LinkButton>
      )}
    </>
  );
};

export default ReadMore;
